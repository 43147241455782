import { createContext, useState } from "react";
import { DateTime } from "luxon";
import { role, User } from "../types";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga4";
import Intercom from "@intercom/messenger-js-sdk";
interface GlobalContext {
  user: User | null;
  storeUser: (user: any) => void;
}

export const isPortfolioCompanyUserOrAdmin = (user: User) => {
  return (
    (user?.role == role.company &&
      user?.company?.cohortMembership?.length > 0) ||
    user.role == role.admin
  );
};

export const UserContext = createContext<GlobalContext>({
  user: null,
  storeUser: () => {},
});
export function UserProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState({});
  const storeUser = (user: User) => {
    setUser({
      ...user,
    });
    Bugsnag.setUser(
      user.id.toString(),
      user.email,
      `${user.firstName} ${user.lastName}`
    );
    ReactGA.set({ userId: user.id, user_id: user.id });
    // Store the user in local storage for later reloads
    localStorage.setItem("user", JSON.stringify(user));
    // Store an expiration countdown for when to kick them back to the login page
    // We do this as some NextJS pages will load without making an API request (auto redirects to login)
    // And instead when they first hit the site, if we know they won't have api access, just make them log back in now
    localStorage.setItem(
      "backendJwtExpirationDate",
      DateTime.now().plus({ days: 14 }).toISO()
    );
  };

  if (typeof window !== "undefined") {
    const backendJwtExpirationDate = localStorage.getItem(
      "backendJwtExpirationDate"
    );
    if (backendJwtExpirationDate) {
      const expirationDate = DateTime.fromISO(backendJwtExpirationDate);
      if (expirationDate < DateTime.now()) {
        localStorage.removeItem("backendJwtExpirationDate");
        localStorage.removeItem("user");
        // redirect?
      }
    }
    const userFromStorage = localStorage.getItem("user") as string;
    user = JSON.parse(userFromStorage);

    if (user) {
      Intercom({
        app_id: "ic63pa6g",
        user_id: (user as User).id.toString(),
        name: `${(user as User).firstName} ${(user as User).lastName}`,
        email: (user as User).email,
        // created_at: user.created, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    }
  }
  return (
    <UserContext.Provider
      value={{
        user: user as User | null,
        storeUser: storeUser as any,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
